var Gui = new function(){
   
    this.init = function(){
        var self = this;
        this.scroll();
        DownloadSimulation.init();
        this.homePlayers();
    };
  
    this.change_blur = function(){
        $(".box").addClass("box-blur");
        $(".box-blur").one("animationend",function() {
                $(".box").removeClass("box-blur");
            });
        };
    
    this.scroll = function(){
        this.margin = 20;  
        this.margin = 0;  
        var self = this;
        $("a[href^='#']").on('click', function(e) {
            e.preventDefault();
            var hash = this.hash;
            var move_to = ($(hash).offset().top - self.margin);
            $('html, body').animate({
                scrollTop: move_to
            }, 300, function(){
                window.location.hash = hash;
                self.change_blur();
            });
        });
    };
    
    this.homePlayers = function(){
    var down = false;

    $(".for-player-holder .button").on("click",function(){
    if(down == false){
        $(".for-player-holder").addClass("offer-big").removeClass("offer-small");
        $("i").removeClass("fa-arrow-down").addClass("fa-arrow-up");
        $('#info').css("display", "none");
        down = true;
    }else{
        $(".for-player-holder").removeClass('offer-big').addClass("offer-small");
        $("i").removeClass("fa-arrow-up").addClass("fa-arrow-down");
        $('#info').css("display", "inline");
        down = false;
    };
});
    }
};
