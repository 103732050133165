var DownloadSimulation = new function(){
    this.bars=[];
    this.is_animated = false;
    this.is_enable = false;
    this.i = 0;
    this.i_row = 0;
   
    this.init = function(){
        this.modalSimulation();
        this.buttonSimulation();
    };
    
    this.creatBars= function(){
        var self = this;
        var max_width = $(".my-progress").width();
        var bars = $('.my-bar');
        bars.each(function(index, bar){
            self.bars[index] = new DownloadBars({
               el: $(bar), 
               max_width: max_width,
               time: $(bar).data("time"),
               weight: $(bar).data("weight")
            });
        });
    };
  
    this.myProgressWidth = function(){
        var progress_width = $(".my-progress").width();
        $(".download-size").css("width", progress_width);
    };
  
    
    this.buttonSimulation = function(){
      
        $(".my-bar").hover(function(){
            var weight = $(this).data('weight');
            $('.button-row[data-weight='+weight+']').addClass("button-adnimation");
        }).mouseout(function(){
           var weight = $(this).data('weight');
            $('.button-row[data-weight='+weight+']').removeClass("button-adnimation");
        });
        
        $(".download-size").hover(function(){
            var weight = $(this).siblings('.my-bar').data('weight');
            $('.button-row[data-weight='+weight+']').addClass("button-adnimation");
        }).mouseout(function(){
            var weight = $(this).siblings('.my-bar').data('weight');
            $('.button-row[data-weight='+weight+']').removeClass("button-adnimation");
        });
        
    };
    
    this.startRowBars = function(weight){
        $.each(this.bars, function(index, bar) {
            bar.rowBarsMove(weight); 
        });
    };
    
    this.restartBarsAnimation = function(){
        var self = this;
        $.each(this.bars, function(index, bar) {
            //self.i = index;
            bar.progressMove(true);
        });
    };
    
    this.startBarsAnimation = function(){
        $.each(this.bars, function(index, bar) {
            bar.progressMove(); 
        });
    };
    
    this.modalSimulation = function(){
        var self = this;
        $('#simulation-modal').on('shown.bs.modal', function(){
            self.myProgressWidth();
            self.creatBars();
            $("#start").on("click", function(){
                DownloadSimulation.i = 0;
                self.is_enable = true;
                self.is_animated = true;
                self.restartBarsAnimation();
            });
            
            $('#stop').on('click', function() {
                if(self.is_enable == true){
                    if(self.is_animated == true){
                        $('#stop').html("Wznów symulację");
                        self.is_animated = false;
                        self.stopAnimation();
                    }
                    else{
                        $('#stop').html("Zatrzymaj symulację");
                        self.is_animated = true;
                        self.startBarsAnimation();
                    }
                }
            });
            
            $(".button-row").on("click", function(){
               var weight = $(this).data('weight');
               self.is_enable = true;
               self.is_animated = true;
               self.startRowBars(weight);
            });
        });
    };
    
    this.disablePauseButton = function (){
        var self = this;
        self.is_enable = false;
        
        for(var i = 0; i < this.bars.length; i++) {
          
           if(this.bars[i].is_animated == true){
                 self.is_enable = true;
                 break;
            }else{
                self.is_enable = false;
            }
        }
    };
    
    this.stopAnimation = function(){
        $.each(this.bars, function(index, bar) {
            bar.pause();
        });  
    };
};
