function SlidersControlls(s){
    this.id = s.id;
    this.el = null;
    this.buttons = null;
    this.state_yes = true;
    
    this.init = function(){
        this.el = $('#'+this.id);
        this.buttons = this.el.find(".slider-button");
       
        this.defaultSettings();
    };
    
    this.defaultSettings = function (){
        var self = this;
        
        this.buttons.each(function(index, control){
            if($(control).data('type') == 'yes'){
                SlidersManager.reset = true;
                $(control).addClass("active-button");
                SlidersManager.setValues(self.id, $(control).data('type'));
            }else{
                $(control).removeClass("active-button");
            }
        });
    };
    
    this.changeState = function(state){
        var self = this;
        
        this.buttons.each(function(index, control){

            if($(control).data() == state){
                $(control).addClass("active-button");
                SlidersManager.setValues(self.id, $(control).data('type'));
            }else{
                $(control).removeClass("active-button");
            }
        });
    };
    
    this.usetSettings = function(){
        SlidersManager.reset = false;
        var self = this;
        
        this.buttons.each(function(index, control){
            if($(control).data('type') == 'no'){
                $(control).addClass("active-button");
                SlidersManager.setValues(self.id, $(control).data('type'));
            }else{
                $(control).removeClass("active-button");
            }
        });
    };
}