var PersonalOffer = new function(){
    this.actual_offer = $("#offer-12");
    this.file = "./assets/json/personal.json";
   
    this.selector = '#personal-offer .change-offer';
    this.json = false;
    this.buttons = {};
    this.init = function(){
        var self = this;
        $.getJSON(this.file, function(data){
            self.json = data;
        });
        this.buttons = $(this.selector);
        this.bind();
    };
    
    this.bind = function(){
        this.buttonsClick();
    };
    
    this.buttonsClick = function(){
        var self = this;
        this.buttons.on('click', function(){
            self.buttons.removeClass('active');
            $(this).addClass('active');
            
            self.change_prize(this);
        });
    };
 
    this.change_prize = function(button){
        var self = this;
        
        setTimeout(function() {
            var time = $(button).data('time');
            $("#prize-1").text(self.json.price[time].radio[4]);
            $("#prize-2").text(self.json.price[time].radio[6]);
            $("#prize-3").text(self.json.price[time].radio[8]);
            $("#prize-4").text(self.json.price[time].radio[10]);
    
            $(".offer-time").text(self.json.price[time].title);
            }, 1000);
        /*
        $(".box").one("animationend", function() {
            var time = $(button).data('time');
        
            $("#prize-1").text(self.json.price[time].radio[4]);
            $("#prize-2").text(self.json.price[time].radio[6]);
            $("#prize-3").text(self.json.price[time].radio[8]);
            $("#prize-4").text(self.json.price[time].radio[10]);
    
            $(".offer-time").text(self.json.price[time].title);
    } );
    */
    
  };
        
    this.send_result = function(){};
};
