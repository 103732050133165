function Sliders(s){
    this.json = s.json;
    this.type = s.slider_type;
    this.values;
    this.id = s.id;
    this.el = null;
    this.css_color = '';
    this.position_default = 0;
    
    this.init = function(){
        this.el = $('#'+this.id);
        this.content = this.el.find(".content");
        this.el_handle = this.el.find(".handle");
        this.css_color = this.el_handle.css('background-color');
        this.slider_init();
        this.reset();
    };
    
    this.slider_init = function(){
        var self = this;
        this.dragdealer = new Dragdealer(this.id, {
            steps: Object.keys(self.json[self.type]).length,
            x: 0,
            css3: true,
            animationCallback: function(x){
                handle_distance = (x * 100) + "%";
                
                self.el.css({
                   "background": 'linear-gradient(90deg, '+ self.css_color + ', ' + handle_distance + ', white ' + handle_distance + ')'
                });
                self.content.html(self.json[self.type][this.getStep()[0]-1].desc);
            }, callback: function(){
                SlidersManager.setValues(self.type, self.json[self.type][this.getStep()[0]-1].value);
            }   
        });
    };
    
    this.reset = function(){
        SlidersManager.reset = true;
        var length = Object.keys(this.json[this.type]).length;
        for( var c = 0; c < length; c++)
        {
            if(this.json[this.type][c].default == "true"){
                this.dragdealer.setStep(c+1, 0);
            }
            
        }
    };
    
    this.userSettings = function(){
        SlidersManager.reset = false;
        
        var length = Object.keys(this.json[this.type]).length;
        for( var c = 0; c < length; c++)
        {
            if(this.json[this.type][c].default == "true"){
                this.dragdealer.setStep(0, 0);
            }
        }
    };
};