var Navbar = new function(){
    this.lastScrollTop = 0;
    this.orangeNavbar = $('.menu-holder').offset().top + $('.menu-holder').outerHeight();
    
    this.init = function(){
        var self = this;
        
        $(window).scroll(function(event){
            self.hasScrolled(event);
        });
    };
/*
    this.hasScrolled = function() {
        var st = $(document).scrollTop();
        if(st >= this.orangeNavbar + 60){
            $('#nav-background-holder').hide();
            $('.menu-holder').hide();
            $('header').addClass('small');

            if(Math.abs(this.lastScrollTop - st) <= this.delta)
                return;

            if (st > this.lastScrollTop && st > this.navbarHeight){
                // Scroll Down
                $('header .menu').hide();
                $('.menu-holder').hide();
            } else {
                // Scroll Up
                if(st + $(window).height() < $(document).height()) {
                    $('.menu-holder').show();
                    $('header .menu').show();
                }
            }
            this.lastScrollTop = st;
        }
        else{
            $('.menu-holder').show();
            $('header .menu').show();
            $('header').removeClass('small');
            $('#nav-background-holder').show();
            $('.menu-holder').show();
            this.start = true;
        }
    };
    */
   
   this.hasScrolled = function(e) {
       
       var st = $(document).scrollTop();
               
       if(st > this.orangeNavbar)  // bellow top menu
        {
            $('header').addClass('small');
            $('#nav-background-holder').hide();
            
            if(st < this.lastScrollTop) // if I go back top
            {
                this.showMenu();
            }else{
                this.hideMenu();
            }
        }
        else
        {
            this.showMenu(true);
        }
        
        this.lastScrollTop = st;
    };
    
    this.showMenu = function(reset)
    {
        if(reset)
        {
            $('header').removeClass('small');
            $('#nav-background-holder').show();
            $('.menu-holder').removeClass('nav-down').removeClass('nav-up');
        }
        else
        {
            $('.menu-holder').addClass('nav-down').removeClass('nav-up').find('.dropdown').css('display', 'block');
        }
    };
    
    this.hideMenu = function()
    {
        $('.menu-holder').addClass('nav-up').removeClass('nav-down').find('.dropdown').css('display', 'none');
    // $('.menu-holder').fadeOut();
    };
};