var SlidersManager = new function(){
    this.sliders = [];
    this.slidersControlls = [];
    this.file = "./assets/json/sliders.json";
    this.json = false;
    this.values = [];
    this.values_custom = [];
    this.reset = false;
    
    this.init = function(){
        var self = this;
        
        $.getJSON(this.file, function(data){
            self.json = data;
            self.createSliders();
            self.createSlidersControlls();
            self.setUserSettings();
            self.setDefault();
            self.SlidersControllsChange();
        });
    };

    this.createSliders = function(){
        var self = this;
        var sliders = $(".dragdealer");
        
        sliders.each(function(index, slider){
            self.sliders[index] = new Sliders({
               slider_type: $(slider).find(".handle").data("type"),
               id: $(slider).attr("id"),
               json: self.json
            });
            self.sliders[index].init();
        });
    };
    
    this.createSlidersControlls = function(){
        var self = this;
        var slidersControlls = $(".offer-addons");
        
        slidersControlls.each(function(index, sliderControll){
            self.slidersControlls[index] = new SlidersControlls({
               id: $(sliderControll).attr("id")
            });
            self.slidersControlls[index].init();
        });
    };
    
    this.setValues = function(key, value){
        this.values[key] = value;
        //console.log(this.values);
        
        if(this.reset == true){
            $("#user-settings").removeClass("active");
            $("#default-settings").addClass("active");
            this.reset = false;
        }else{
            $("#user-settings").addClass("active");
            $("#default-settings").removeClass("active");
        }
    };
    
    this.setDefault = function(){
        var self = this;
        $("#default-settings").on("click", function(){
            $(self.sliders).each(function(index, slider){
                slider.reset();
            });
            self.SlidersControllsReset();
        });
    };
    
    this.setUserSettings = function(){
        var self = this;
        $("#user-settings").on("click", function(){
            $(self.sliders).each(function(index, slider){
                slider.userSettings();
            });
            self.SlidersControllsUser();
        });
    };
    
    this.SlidersControllsChange = function(){
        var self = this;
        
        $('#protected .slider-button').on("click", function(){
            self.slidersControlls[0].changeState($(this).data());
        });
        
        $('#i24help .slider-button').on("click", function(){
            self.slidersControlls[1].changeState($(this).data());
        });
    };
    
    this.SlidersControllsReset = function(){
       $(this.slidersControlls).each(function(index, control){
            control.defaultSettings();
       });
    };
    
    this.SlidersControllsUser = function(){
       $(this.slidersControlls).each(function(index, control){
            control.usetSettings();
       });
    };
};