var Logger = function()
{
    var debug = true;
    
    var log = function(variable){
        //if(this.debug)
            alert(arguments.callee.caller.toString().match(/showMe\((\S)\)/)[1] + 
    ' = '+ variable);
            //console.log(variable);
    };
};