function DownloadBars(s){
    this.min_width = 0;
    this.max_width = s.max_width;
    this.actual_width = 0;
    this.el = s.el;
    this.time = s.time;
    this.weight = s.weight;
    this.used_time = 0;
    this.is_animated = false;
    //this.init = function(){};

    this.rowBarsMove = function(weight){
        var self = this;
        
        if(this.weight == weight){
            this.el.css('width', this.min_width);
            $(this.el).removeClass('progress-finished').addClass('my-active progress-bar-striped').parent().removeClass('progress-finished');
            
            this.progressMove(true);
        }
    };

    this.progressMove = function(reset){
        var self = this;
        if(typeof(reset) != "undefined"){
            this.el.css('width', this.min_width);
            $(this.el).removeClass('progress-finished').addClass('my-active progress-bar-striped').parent().removeClass('progress-finished');
            self.used_time = 0;
        }else{
            actualWidth = $(this.el).width();
            self.used_time = actualWidth * this.time / this.max_width;
        }
        
        this.is_animated = true;
        
        $(this.el).stop().animate({
            width: self.max_width
            }, 
            self.time - self.used_time,
            function (){
                self.finish();
            }
        );
    };

    this.finish = function(){
        this.is_animated = false;
        DownloadSimulation.disablePauseButton();
        $(this.el).addClass('progress-finished').removeClass('my-active progress-bar-striped').parent().addClass('progress-finished');
    };

    this.pause = function(){
        var self = this;
        this.actual_width = this.el.width();
        
        $(this.el).stop().css({
            width: self.actual_width
        });
    };
};